import { useEffect, useState } from 'react'

import { Modal, TextInput, Group, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  CREATE_TEXT_TEMPLATE,
  EDIT_TEXT_TEMPLATE,
} from 'src/graphql/textTemplates.graphql'

//This should reflect variables found in api/src/lib/textTemplates.ts
const TextTemplateVariables = [
  { name: 'Company Name', value: 'CompanyName' },
  { name: 'Employee Full Name', value: 'EmployeeFullName' },
  { name: 'Employee First Name', value: 'EmployeeFirstName' },
  { name: 'Employee Phone Number', value: 'EmployeePhoneNumber' },
  { name: 'Applicant Full Name', value: 'ApplicantFullName' },
  { name: 'Applicant First Name', value: 'ApplicantFirstName' },
  { name: 'Application Link', value: 'ApplicationLink' },
  { name: 'Job Listing Name', value: 'JobListingName' },
]

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const CreateTemplateModal = ({
  textTemplate,
  isOpen,
  refetchQueries,
  onClose,
  edit = false,
}) => {
  const [cursorPosition, setCursorPosition] = useState(0)

  //FORM SETUP:
  const schema = z.object({
    title: z
      .string()
      .min(1, { message: 'Please include a title' })
      .max(100, { message: 'Template title is too long' }),
    content: z
      .string()
      .min(1, { message: 'Please include a text template' })
      .max(500, { message: 'Template content is too long' }),
  })

  const form = useForm({
    initialValues: {
      title: textTemplate?.title || '',
      content: textTemplate?.content || '',
    },
    validate: zodResolver(schema),
  })

  useEffect(() => {
    form.setValues({
      title: textTemplate?.title || '',
      content: textTemplate?.content || '',
    })
  }, [textTemplate])

  //MUTATIONS:
  const [editTextTemplate] = useMutation(EDIT_TEXT_TEMPLATE, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast('Your text template was updated successfully', 'success')
      onClose()
    },
    onError,
  })

  const [createTextTemplate] = useMutation(CREATE_TEXT_TEMPLATE, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast('A new text template was created successfully', 'success')
      onClose()
    },
    onError,
  })

  const onFormSubmit = (values) => {
    edit
      ? editTextTemplate({
          variables: {
            textTemplateId: textTemplate.textTemplateId,
            input: {
              ...values,
            },
          },
        })
      : createTextTemplate({
          variables: {
            input: {
              ...values,
            },
          },
        })
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size={'lg'}
      classNames={{ title: 'text-lg font-bold' }}
      title={edit ? 'Edit SMS Template' : 'Create SMS Template'}
      centered
    >
      <div className="flex flex-col gap-6">
        <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
          <div className="flex flex-col gap-8">
            <TextInput
              placeholder={
                form.values.title
                  ? (form.values.title as string)
                  : 'SMS Template # 1'
              }
              label="Template Name"
              {...form.getInputProps('title')}
            />
            <div className="flex flex-col gap-4">
              <div className="test-doubleNickel-gray-900 text-sm font-medium">
                Click on the variable to insert it in the template
              </div>
              <div className="flex flex-row flex-wrap gap-2">
                {TextTemplateVariables.map((variable) => (
                  <StyledBadge
                    key={variable.value}
                    textTransform="capitalize"
                    onClick={() =>
                      //This sets the variable at the selected cursor position
                      form.setFieldValue(
                        'content',
                        form.values.content.slice(0, cursorPosition) +
                          `{{${variable.value}}}` +
                          form.values.content.slice(cursorPosition)
                      )
                    }
                  >
                    {variable.name}
                  </StyledBadge>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-1.5">
              <Textarea
                maxRows={6}
                classNames={{
                  input: 'h-36',
                }}
                placeholder={
                  form.values.content
                    ? (form.values.content as string)
                    : 'Write here...'
                }
                value={form.values.content as string}
                error={form.errors['content']}
                onClick={(e) => {
                  //This tracks the cursor position to insert the variable at the right place
                  setCursorPosition(e.target.selectionStart)
                }}
                onChange={(e) => {
                  form.setFieldValue('content', e.target.value)
                  setCursorPosition(e.target.selectionStart)
                }}
              />
              <div className="text-xs font-medium text-doubleNickel-gray-600">
                {form.values.content?.length || 0}/500
              </div>
            </div>
          </div>
          <Group
            justify="flex-end"
            mt="md"
            className="grid grid-cols-2 gap-4 border-t py-4"
          >
            <Button text="Cancel" variant="outline" onClick={onClose} />
            <Button
              type="submit"
              text={edit ? 'Save' : 'Create'}
              variant="filled"
            />
          </Group>
        </form>
      </div>
    </Modal>
  )
}

export default CreateTemplateModal
